document.addEventListener("DOMContentLoaded", function () {
  const main = new Main();
});

class Main {
  constructor() {
    this.header = document.querySelector(".header");
    this.sides = document.querySelectorAll(".side");
    this._observers = [];
    this._init();
  }

  set observers(val) {
    this._observers.push(val);
  }

  get observers() {
    return this._observers;
  }

  _init() {
    new MobileMenu();
    // Pace.on("done", this._paceDone.bind(this));
  }

  _paceDone() {
    this._scrollInit();
  }

  _inviewAnimation(el, inview) {
    if (inview) {
      el.classList.add("inview");
    } else {
      el.classList.remove("inview");
    }
  }

  _navAnimation(el, inview) {
    if (inview) {
      this.header.classList.remove("triggered");
    } else {
      this.header.classList.add("triggered");
    }
  }

  _destroyObservers() {
    this.observers.forEach((ob) => {
      ob.destroy();
    });
  }

  destroy() {
    this._destroyObservers();
  }

  _scrollInit() {
    this.observers = new ScrollObserver(
      ".nav-trigger",
      this._navAnimation.bind(this),
      { once: false }
    );
    this.observers = new ScrollObserver(".cover-slide", this._inviewAnimation);
    this.observers = new ScrollObserver(".appear", this._inviewAnimation);
  }
}

$(function () {
  /*=================================================
    スマホメニュー
    ===================================================*/
  // ハンバーガーメニューのクリックイベント
  // 解説は、「中級編：ストアサイト（インテリア）」参照
  $(".toggle_btn").on("click", function () {
    if ($(".video__wrap").hasClass("open")) {
      $(".video__wrap").removeClass("open");
    } else {
      $(".video__wrap").addClass("open");
    }
  });

  // #maskのエリアをクリックした時にメニューを閉じる
  $("#mask").on("click", function () {
    $(".video__wrap").removeClass("open");
  });

  // リンクをクリックした時にメニューを閉じる
  $("#navi a").on("click", function () {
    $(".video__wrap").removeClass("open");
  });
});

//logoの表示
$(window).on("load", function () {
  $("#splash").delay(2600).fadeOut("slow");
  $("#splash_logo").delay(2000).fadeOut("slow");
});

$(function () {
  $(".slider").slick({
    autoplay: true, // 自動でスクロール
    autoplaySpeed: 0, // 自動再生のスライド切り替えまでの時間を設定
    speed: 12000, // スライドが流れる速度を設定
    cssEase: "linear", // スライドの流れ方を等速に設定
    slidesToShow: 3, // 表示するスライドの数
    swipe: false, // 操作による切り替えはさせない
    arrows: false, // 矢印非表示
    pauseOnFocus: false, // スライダーをフォーカスした時にスライドを停止させるか
    pauseOnHover: false, // スライダーにマウスホバーした時にスライドを停止させるか
    responsive: [
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1, // 画面幅750px以下でスライド3枚表示
        },
      },
    ],
  });
});

// ページ内リンク
// $('a[href*="#"]').click(function () {
//   var elmHash = $(this).attr('href'); //ページ内リンクのHTMLタグhrefから、リンクされているエリアidの値を取得
//   var pos = $(elmHash).offset().top;  //idの上部の距離を取得
//   $('body,html').animate({scrollTop: pos}, 500); //取得した位置にスクロール。500の数値が大きくなるほどゆっくりスクロール
//   return false;
// });

// フッタースクロール
$(function() {
  // 変数にクラスを入れる
  var btn = $('.button');
  
  //スクロールしてページトップから100に達したらボタンを表示
  $(window).on('load scroll', function(){
    if($(this).scrollTop() > 100) {
      btn.addClass('active');
    }else{
      btn.removeClass('active');
    }
  });
  
  //フッターの手前でボタンを止める
  $(window).on('load scroll', function(){
    var height = $(document).height(), //ドキュメントの高さ 
        position = window.innerHeight + $(window).scrollTop(), //ページトップから現在地までの高さ
        footer = $('footer').height(); //フッターの高さ
    if ( height - position  < footer ){ 
      btn.addClass('absolute');
    } else { 
      btn.removeClass('absolute');
    }
  });
  
  //スクロールしてトップへ戻る
  btn.on('click',function () {
    $('body,html').animate({
      scrollTop: 0
    });
  });
});
// var pagetopBtn = $(".footer__scroll");
// pagetopBtn.hide();

// $(window).scroll(function () {
//   if ($(this).scrollTop() > 100) {
//     pagetopBtn.fadeIn();
//   } else {
//     pagetopBtn.fadeOut();
//   }
// });

// $(window).scroll(function () {
//   var height = $(document).height();
//   var position = $(window).height() + $(window).scrollTop();
//   var footer = $("footer").outerHeight();
//   if (height - position < footer) {
//     pagetopBtn.css({
//       bottom: 200,
//     });
//   } else {
//     pagetopBtn.css({
//       position: "fixed",
//       bottom: 30,
//     });
//   }
// });

// pagetopBtn.click(function () {
//   $("body,html").animate(
//     {
//       scrollTop: 0,
//     },
//     500
//   );
//   return false;
// });


//アニメーション
ScrollReveal().reveal('.ttl', { 
  duration: 1700, // アニメーションの完了にかかる時間
  viewFactor: 0.2, // 0~1,どれくらい見えたら実行するか
  reset: false   // 何回もアニメーション表示するか
});
ScrollReveal().reveal('.ttl__sp', { 
  duration: 1700, // アニメーションの完了にかかる時間
  viewFactor: 0.2, // 0~1,どれくらい見えたら実行するか
  reset: false   // 何回もアニメーション表示するか
});
ScrollReveal().reveal('.news__ul',  { 
  duration: 2300, // アニメーションの完了にかかる時間
  viewFactor: 0.2, // 0~1,どれくらい見えたら実行するか
  reset: false   // 何回もアニメーション表示するか
});
ScrollReveal().reveal('.news__more',  { 
  duration: 2300, // アニメーションの完了にかかる時間
  viewFactor: 0.2, // 0~1,どれくらい見えたら実行するか
  reset: false   // 何回もアニメーション表示するか
});
ScrollReveal().reveal('.news__more',  { 
  duration: 2300, // アニメーションの完了にかかる時間
  viewFactor: 0.2, // 0~1,どれくらい見えたら実行するか
  reset: false   // 何回もアニメーション表示するか
});
ScrollReveal().reveal('.about__ttl',  { 
  duration: 2300, // アニメーションの完了にかかる時間
  viewFactor: 0.2, // 0~1,どれくらい見えたら実行するか
  reset: false   // 何回もアニメーション表示するか
});
ScrollReveal().reveal('.about__ttl-sp',  { 
  duration: 2300, // アニメーションの完了にかかる時間
  viewFactor: 0.2, // 0~1,どれくらい見えたら実行するか
  reset: false   // 何回もアニメーション表示するか
});
ScrollReveal().reveal('.about__txt',  { 
  duration: 2800, // アニメーションの完了にかかる時間
  viewFactor: 0.7, // 0~1,どれくらい見えたら実行するか
  reset: false   // 何回もアニメーション表示するか
});
ScrollReveal().reveal('.shef__ttl',  { 
  duration: 2300, // アニメーションの完了にかかる時間
  viewFactor: 0.2, // 0~1,どれくらい見えたら実行するか
  reset: false   // 何回もアニメーション表示するか
});
ScrollReveal().reveal('.shef__ttl-sp',  { 
  duration: 2300, // アニメーションの完了にかかる時間
  viewFactor: 0.2, // 0~1,どれくらい見えたら実行するか
  reset: false   // 何回もアニメーション表示するか
});
ScrollReveal().reveal('.shef__txt',  { 
  duration: 2800, // アニメーションの完了にかかる時間
  viewFactor: 0.7, // 0~1,どれくらい見えたら実行するか
  reset: false   // 何回もアニメーション表示するか
});
ScrollReveal().reveal('.farm__ttl',  { 
  duration: 2300, // アニメーションの完了にかかる時間
  viewFactor: 0.2, // 0~1,どれくらい見えたら実行するか
  reset: false   // 何回もアニメーション表示するか
});
ScrollReveal().reveal('.farm__ttl-sp',  { 
  duration: 2300, // アニメーションの完了にかかる時間
  viewFactor: 0.2, // 0~1,どれくらい見えたら実行するか
  reset: false   // 何回もアニメーション表示するか
});
ScrollReveal().reveal('.farm__txt',  { 
  duration: 2800, // アニメーションの完了にかかる時間
  viewFactor: 0.7, // 0~1,どれくらい見えたら実行するか
  reset: false   // 何回もアニメーション表示するか
});

ScrollReveal().reveal('.menu__right > h2',  { 
  duration: 2800, 
  origin: 'right', 
  distance: '50px',
  reset: false   // 何回もアニメーション表示するか
});
ScrollReveal().reveal('.menu__des-left',  { 
  duration: 2800, // アニメーションの完了にかかる時間
  viewFactor: 0.7, // 0~1,どれくらい見えたら実行するか
  reset: false   // 何回もアニメーション表示するか
});
ScrollReveal().reveal('.menu__des-right',  { 
  duration: 2300, // アニメーションの完了にかかる時間
  viewFactor: 0.2, // 0~1,どれくらい見えたら実行するか
  reset: false   // 何回もアニメーション表示するか
});
ScrollReveal().reveal('.menu__img-left',  { 
  duration: 2800, // アニメーションの完了にかかる時間
  viewFactor: 0.7, // 0~1,どれくらい見えたら実行するか
  reset: false   // 何回もアニメーション表示するか
});
ScrollReveal().reveal('.menu__img-right',  { 
  duration: 2800, // アニメーションの完了にかかる時間
  viewFactor: 0.7, // 0~1,どれくらい見えたら実行するか
  reset: false   // 何回もアニメーション表示するか
});
ScrollReveal().reveal('.dinner__right',  { 
  duration: 2800, 
  origin: 'left', 
  distance: '50px',
  reset: false   // 何回もアニメーション表示するか
});

ScrollReveal().reveal('.dinner__des-left',  { 
  duration: 2800, // アニメーションの完了にかかる時間
  viewFactor: 0.7, // 0~1,どれくらい見えたら実行するか
  reset: false   // 何回もアニメーション表示するか
});
ScrollReveal().reveal('.dinner__des-right',  { 
  duration: 2300, // アニメーションの完了にかかる時間
  viewFactor: 0.2, // 0~1,どれくらい見えたら実行するか
  reset: false   // 何回もアニメーション表示するか
});
ScrollReveal().reveal('.dinner__img-left',  { 
  duration: 2800, // アニメーションの完了にかかる時間
  viewFactor: 0.7, // 0~1,どれくらい見えたら実行するか
  reset: false   // 何回もアニメーション表示するか
});
ScrollReveal().reveal('.dinner__img-right',  { 
  duration: 2800, // アニメーションの完了にかかる時間
  viewFactor: 0.7, // 0~1,どれくらい見えたら実行するか
  reset: false   // 何回もアニメーション表示するか
});
ScrollReveal().reveal('.shop__txt',  { 
  duration: 2800, // アニメーションの完了にかかる時間
  viewFactor: 0.7, // 0~1,どれくらい見えたら実行するか
  reset: false   // 何回もアニメーション表示するか
});
ScrollReveal().reveal('.shop__ttl',  { 
  duration: 2200, // アニメーションの完了にかかる時間
  viewFactor: 0.7, // 0~1,どれくらい見えたら実行するか
  reset: false   // 何回もアニメーション表示するか
});
ScrollReveal().reveal('.shop__ttl-sp',  { 
  duration: 2800, // アニメーションの完了にかかる時間
  viewFactor: 0.7, // 0~1,どれくらい見えたら実行するか
  reset: false   // 何回もアニメーション表示するか
});



